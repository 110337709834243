function insertImportMap(importMap) {
  const script = document.createElement('script');
  script.type = 'systemjs-importmap';
  script.textContent = JSON.stringify(importMap);

  const overrideMapElement = document.querySelector('[data-is-importmap-override]');
  if (overrideMapElement) {
    overrideMapElement.parentNode.insertBefore(script, overrideMapElement);
  } else {
    document.head.appendChild(script);
  }
}

function insertImportMapWithUrl(url) {
  const script = document.createElement('script');
  script.type = 'systemjs-importmap';
  script.src = url;

  const overrideMapElement = document.querySelector('[data-is-importmap-override]');
  if (overrideMapElement) {
    overrideMapElement.parentNode.insertBefore(script, overrideMapElement);
  } else {
    document.head.appendChild(script);
  }

  (window as any).System.prepareImport(true);
}

export function checkAndLoadImportMapsByApp() {
  const { SYSTEM_IMPORT_FILE_NAME } = process.env;

  insertImportMapWithUrl(`/${SYSTEM_IMPORT_FILE_NAME}`);
}
